@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.mainload {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('/src/bg.png');
  height: 100vh;
  background-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  position: absolute;
  bottom: 4vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.fotbtns {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 100%;
}

.fotbtns button {
  /* Social Buttons */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 19px;

border: none;

font-family: 'Krona One';
font-style: normal;
font-weight: 400;
font-size: 12px;
/* identical to box height, or 78% */
text-align: center;
letter-spacing: -0.02em;

/* Primary/Base/White */
color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;


background: rgba(47, 110, 255, 0.8);
}

.selectfot {

  background: #567BD0 !important;

  
}